import {Reducer} from 'redux'
import {createSagaAction} from '../../shared/sagas/sagas'

// Constants (Action Types)
export const constants = {
  GET_EMPRESA_CONFIG: createSagaAction('GET_EMPRESA_CONFIG'),
  UPDATE_EMPRESA_CONFIG: createSagaAction('UPDATE_EMPRESA_CONFIG'),
}

/**
 * Data types
 */
export interface EmpresaConfig {
  adm_empresa_id: number
  pub_sessao_id?: number
  inverter_tag_id_integracao_api?: number
  parcelamento: number

  created_at?: string
  updated_at?: string
}

/**
 * State type
 */
export interface EmpresaConfigState {
  readonly data: EmpresaConfig | null
  readonly loading: boolean
  readonly error: boolean
}

// Reducer
export const initialState: EmpresaConfigState = {
  data:  null,
  loading: false,
  error: false,
}

// Action Creators
export const actions = {
  getEmpresaConfig: () => ({type: constants.GET_EMPRESA_CONFIG.ACTION}),
  updateEmpresaConfig: (empresaConfig: EmpresaConfig) => ({
    type: constants.UPDATE_EMPRESA_CONFIG.ACTION,
    empresaConfig,
  }),
}

export const reducer: Reducer<EmpresaConfigState> = (state = initialState, action) => {
  switch (action.type) {
    case constants.GET_EMPRESA_CONFIG.ACTION:
      return {...state, loading: true}
    case constants.GET_EMPRESA_CONFIG.SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        data: action.data,
      }
    case constants.GET_EMPRESA_CONFIG.FAILED:
      return {
        ...state,
        loading: false,
        error: action.error,
        data: null,
      }
    case constants.UPDATE_EMPRESA_CONFIG.ACTION:
      return {...state, loading: true}
    case constants.UPDATE_EMPRESA_CONFIG.SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
      }
    case constants.UPDATE_EMPRESA_CONFIG.FAILED:
      return {
        ...state,
        loading: false,
        error: action.error,
      }
    default:
      return state
  }
}

export default {
  reducer,
  constants,
  actions,
  initialState,
}
